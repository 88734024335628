<script>
import auth from "@/mixins/auth";
import { wpService } from "@/services/wp";
export default {
  name: "activate",

  mixins: [auth],

  props: {
    action_id: {
      type: Number,
    },
    action_key: {
      type: String,
    },
  },

  data() {
    return {
      pass1: "",
      pass2: "",
      loading: false,
      verified: false,
      errors: null,
      countdown: null,
      submited: false,
    };
  },

  created() {
    this.verify();
  },

  computed: {
    invalidPassword() {
      let invalidPassword = [];

      if (!this.submited) return [];
      if (this.pass1.length < 8 || this.pass1.length > 24)
        invalidPassword.push("len");

      if (!this.pass1.length || this.pass1 !== this.pass2)
        invalidPassword.push("match");

      return invalidPassword;
    },
  },

  methods: {
    isWrong(key) {
      return this.invalidPassword.indexOf(key) != -1;
    },
    handleError(error) {
      this.$parent.close();
      if (error.code == "signup-invalid-key") {
        this.$buefy.dialog.alert({
          title: "Activación fallida",
          message:
            "La cuenta no puede ser activada debido a un error de validación.",
          type: "is-danger",
        });
      } else if (error.code == "signup-expired") {
        this.$buefy.dialog.confirm({
          title: "Enlace expirado",
          message:
            "La cuenta no puede ser activada debido que se ha excedido el tiempo de compromiso para confirmación. Si desea, puede registrarse nuevamente.",
          confirmText: "Registrarme",
          cancelText: "Cerrar",
          onConfirm: () => {
            this.authSignup();
          },
        });
      } else {
        this.$buefy.dialog.alert({
          message: error.message ? error.message : "Something's not good",
          type: "is-danger",
        });
      }
    },
    verify() {
      this.loading = true;
      let body = {
        action_id: this.action_id,
        action_key: this.action_key,
      };
      return wpService.account
        .signupVerify(body)
        .then(response => {
          this.countdown = response.expires_in;
        })
        .catch(error => this.handleError(error))
        .finally(() => {
          this.verified = true;
          this.loading = false;
        });
    },
    submit() {
      this.submited = true;
      if (this.invalidPassword.length) return;

      this.loading = true;
      let body = {
        action_id: this.action_id,
        action_key: this.action_key,
        password: this.pass1,
      };
      wpService.account
        .signupComplete(body)
        .then(() => {
          this.$parent.close();
          this.$buefy.dialog.confirm({
            title: "Confirmación exitosa",
            message:
              "Tu cuenta ha sido activada. Ya puedes iniciar sesión con tu correo electrónico y tu nueva contraseña.",
            cancelText: "Cerrar",
            confirmText: "Iniciar sesión",
            onConfirm: () => {
              this.authLogin();
            },
          });
        })
        .catch(error => this.handleError(error))
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<template>
  <div id="authActivate">
    <section>
      <h2 class="title">Activacíon de cuenta</h2>
      <b-loading :active="loading" />
      <div v-if="!verified">
        verificando...
      </div>
      <div v-else>
        <div class="content">
          <p>
            ¡Casi listos! Para activar tu cuenta solo falta que definas una
            contraseña con estos requisitos:
          </p>
          <ul>
            <li :class="isWrong('len') ? 'has-text-danger' : ''">
              <small>Debe contener de 8 a 24 caracteres</small>
            </li>
            <li :class="isWrong('match') ? 'has-text-danger' : ''">
              <small>Ambas contraseñas deben coincidir</small>
            </li>
            <!-- <li :class="isWrong('spaces') ? 'has-text-danger' : ''">
              <small>No debe contener espacios</small>
            </li> -->
            <!-- <li :class="isWrong('chars') ? 'has-text-danger' : ''">
              <small>Solo letras, números y !@#$%&</small>
            </li> -->
          </ul>
        </div>
        <form @submit.prevent="submit">
          <b-field>
            <b-input
              rounded
              expanded
              size="is-medium"
              v-model="pass1"
              type="password"
              password-reveal
              placeholder="Nueva contraseña"
              :disabled="loading"
            />
          </b-field>
          <b-field>
            <b-input
              rounded
              expanded
              size="is-medium"
              v-model="pass2"
              type="password"
              password-reveal
              placeholder="Confirmar contraseña"
              :disabled="loading"
            />
          </b-field>
          <b-button
            native-type="submit"
            type="is-primary"
            :class="{ 'is-loading': loading }"
            size="is-medium"
            rounded
            expanded
            :disabled="loading"
            >Activar cuenta</b-button
          >
        </form>
      </div>
      <hr />
      <countdown
        v-if="countdown"
        :time="(countdown - 10) * 1000"
        @end="expired()"
        class="countdown"
      >
        <template slot-scope="props">
          <strong>Expira en: </strong>
          <span
            class="time"
            :class="{ 'has-text-danger': props.totalMilliseconds < 60000 }"
          >
            <span class="unit" v-if="props.days"
              >{{ props.days }} {{ props.days > 1 ? "dias" : "dia" }}</span
            >
            <span class="unit" v-if="props.hours"
              >{{ props.hours }} {{ props.hours > 1 ? "horas" : "hora" }}</span
            >
            <span class="unit" v-if="props.minutes"
              >{{ props.minutes }}
              {{ props.minutes > 1 ? "minutos" : "minuto" }}</span
            >
            <span class="unit" v-if="props.seconds">
              {{ props.seconds }}
              {{ props.seconds != 1 ? "segundos" : "segundo" }}
            </span>
          </span>
        </template>
      </countdown>
    </section>
  </div>
</template>
